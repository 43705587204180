import { NavLink, useLocation } from "react-router-dom";
import useDarkSide from '../hooks/useDarkSide';
import Logo from "./Logo";
import { BsFillSunFill,BsFillMoonFill } from "react-icons/bs";
import { BiMenu } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { useEffect,  useState } from "react";
const links = [
  { path: "/", title: "home" },
  { path: "/projects", title: "projects" },
  { path: "/articles", title: "articles" },
  { path: "/skills", title: "skills" },
  { path: "/contact", title: "contact" },
];

const Navbar = () => {
  // const [colorTheme, setTheme] = useDarkSide();
  // const [darkSide, setDarkSide] = useState(true);
  const [showNav, setShowNav] = useState(false);
  // const handleDarkmode = () => {
  //   setDarkSide(!darkSide)
  //   setTheme(darkSide ? 'light' : 'dark');
  // };
  const location = useLocation();

  // useEffect(() => {
  //   setShowNav(false);
  // }, [location]);
  return (
    <div className="w-full ">
      <div
        className="flex  capitalize relative h-24 mb-2 md:mb-8 lg:mb-12 mx-auto px-2  
         justify-between items-center"
      >
        <div className="flex gap-3 items-center">
          <button
            onClick={() => setShowNav(true)}
            className="pt-2 shadow-xl lg:hidden"
          >
            <BiMenu size={32} />
          </button>
          <Logo />
        </div>
        <nav className="lg:flex items-center text-lg hidden  gap-4">
          {links.map((link) => (
            <NavLink
              key={link.title}
              to={link.path}
              className={({ isActive, isPending }) =>
                isActive
              ? "text-white"
              : isPending
              ? "font-bold text-[#94A9C9]"
              : "font-bold text-[#94A9C9]"
              }
            >
              {link.title}
            </NavLink>
          ))}
        </nav>

        <div></div>

        {/* <button
          onClick={handleDarkmode}
          to="#"
          className="bg-[#0909093c] p-2 dark:text-yellow-500 text-sky-300 rounded-md px-4"
        >
         {darkSide? <BsFillSunFill />:<BsFillMoonFill />}
        </button> */}
      </div>

      <div
        className={`navmenu w-2/3 h-screen ${
          showNav ? "translate-x-0" : "-translate-x-[150%]"
        }
        duration-300 lg:hidden p-4 top-0 left-0 fixed z-[100] bg-gray-950`}
      >
        <button
          onClick={() => setShowNav(false)}
          className="absolute top-3 lg:hidden z-[100] right-3"
        >
          <FaTimes size={24} />
        </button>
        <Logo />
        <div className="flex-col-start gap-y-4 mt-4">
          {links.map((link, index) => (
            <NavLink
              key={index}
              to={link.path}
              className={({ isActive, isPending }) =>
                isActive
                  ? "text-white"
                  : isPending
                  ? "font-bold text-[#94A9C9]"
                  : "font-bold text-[#94A9C9]"
              }
            >
              {link.title}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
